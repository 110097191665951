<template>
  <div class="home">
      
      <!-- Active Competitions -->
      <section class="container">
        <h1 v-if="this.winners.length > 0" class="text-center mt-5 mb-5">Winners</h1>
        <h1 v-else class="text-center mt-5 mb-5">No Winners</h1>

      <div class="row">
        

        <div v-for="winner in winners" v-bind:key="winner.id" class="col-xs-6 col-sm-12 col-md-6">
        <a target="_blank" v-bind:href="'https://' + winner.link">

        <router-link v-if="this.account && this.account.account && this.account.account.isAdmin" v-bind:to="'/admin/edit-winner/' + winner.id"><button>EDIT</button></router-link>
        
        <div id="competition-item">
               <h1 class="competition-title">{{'Watch Live Replay'}}</h1>
            <img v-bind:src="'data:image/jpeg;base64,'+winner.image" alt=""/>
        </div>       

        <div class="pt-2 pb-3">
          <p style="text-align:center">{{winner.description}}</p>
        </div>


          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="70" viewBox="0 0 702 91">
            <defs>
              <filter id="Rectangle_186" x="0" y="0" width="702" height="91" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_186)">
              <rect id="Rectangle_186-2" data-name="Rectangle 186" width="684" height="73" rx="36.5" transform="translate(9 6)" fill="#4fd1c1"/>
            </g>
            <circle id="Ellipse_538" data-name="Ellipse 538" cx="29.5" cy="29.5" r="29.5" transform="translate(17 13)" fill="#fff"/>
            <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" transform="translate(23.99 20.99)">
              <path id="Path_5" data-name="Path 5" d="M27.357,3.375A24.005,24.005,0,1,0,51.386,27.38,24,24,0,0,0,27.357,3.375Zm.023,43.209a19.2,19.2,0,1,1,19.2-19.2A19.2,19.2,0,0,1,27.38,46.584Z" transform="translate(-4.375 -5.375)" fill="#4fd1c1"/>
              <path id="Path_6" data-name="Path 6" d="M20.138,10.687h-3.6v14.4l12.6,7.559,1.8-2.954-10.8-6.405Z" transform="translate(4.067 -0.685)" fill="#4fd1c1"/>
            </g>
            <path id="Path_65" data-name="Path 65" d="M0,29.5Z" transform="translate(16 13)" fill="#4fd1c1"/>
            <path id="Path_64" data-name="Path 64" d="M0,29.5Z" transform="translate(534 13)" fill="#4fd1c1"/>
            <ellipse id="Ellipse_581" data-name="Ellipse 581" cx="29.5" cy="30" rx="29.5" ry="30" transform="translate(340 14)" fill="#fff"/>
            <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M8.408,10.806H29.429V23.419H8.408Zm26.276,6.306a3.153,3.153,0,0,0,3.153,3.153v6.306a3.153,3.153,0,0,1-3.153,3.153H3.153A3.153,3.153,0,0,1,0,26.572V20.266a3.153,3.153,0,0,0,3.153-3.153A3.153,3.153,0,0,0,0,13.959V7.653A3.153,3.153,0,0,1,3.153,4.5H34.685a3.153,3.153,0,0,1,3.153,3.153v6.306A3.153,3.153,0,0,0,34.685,17.113Zm-3.153-6.832A1.577,1.577,0,0,0,29.955,8.7H7.883a1.577,1.577,0,0,0-1.577,1.577V23.944a1.577,1.577,0,0,0,1.577,1.577H29.955a1.577,1.577,0,0,0,1.577-1.577Z" transform="translate(350.616 26.028)" fill="#4fd1c1"/>
            <ellipse id="Ellipse_582" data-name="Ellipse 582" cx="30" cy="29.5" rx="30" ry="29.5" transform="translate(475 13)" fill="#fff"/>
            <text id="Julius" transform="translate(582 52)" fill="#fff" font-size="26" font-family="NirmalaUI, Nirmala UI"><tspan x="-31.167" y="0">{{winner.name}}</tspan></text>
            <path id="Icon_ionic-md-trophy" data-name="Icon ionic-md-trophy" d="M27.563,7.383c.063-1.9.014-3.937.007-4.008H8.388c0,.07-.056,2.109.007,4.008H3.375c0,4.781.7,7.193,1.477,8.909a12.424,12.424,0,0,0,4.127,4.943,48.959,48.959,0,0,0,7.334,4.05v1.99c-.323.7-1.652,1.983-5.857,1.983H9v3.375H27V29.257H25.193c-4.268,0-5.273-1.343-5.505-1.983v-1.99a78.511,78.511,0,0,0,7.341-4.043A14.51,14.51,0,0,0,31.155,16.3a24.979,24.979,0,0,0,1.47-8.916ZM7.706,14.9a11.591,11.591,0,0,1-1.1-4.352H8.62c.049.422.1.795.162,1.146a31.141,31.141,0,0,0,1.807,6.785A10.086,10.086,0,0,1,7.706,14.9Zm20.588,0a10.157,10.157,0,0,1-2.925,3.607,30.706,30.706,0,0,0,1.814-6.813c.056-.352.112-.724.162-1.146H29.4A11.592,11.592,0,0,1,28.294,14.9Z" transform="translate(486.625 25.625)" fill="#4fd1c1"/>
            <text id="_5" data-name="5" transform="translate(436 52)" fill="#fff" font-size="26" font-family="NirmalaUI, Nirmala UI"><tspan x="-7.008" y="0">{{winner.winningTicket}}</tspan></text>
            <text id="DATE" transform="translate(119 53)" fill="#fff" font-size="26" font-family="NirmalaUI, Nirmala UI"><tspan x="-29.745" y="0">{{winner.date.split("T")[0]}}</tspan></text>
          </svg>


          </a>
          </div>

        </div>
      </section>

  </div>

  <Footer />

</template>

<script>

import Api from '../scripts/api'

import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    },
    props: {
      account: Object
    },
    data() {
      return {
        winners: undefined
      }
    },
    async created() {
      var result = await Api.winnersGet();
      if(result.status == 200) {
        this.winners = await result.json();
      }
    }
}
</script>
